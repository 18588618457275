.photoviewer {

    img {
        max-height: 100%;
        max-width: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    /*
    .captured-photo {
        height: 90%;
        width: 90%;
    }
    .photo-display {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    } */
}
