.add-image-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    h2 {
        margin-left: $margin-left;
        color: $heading-color;
    }
    p {
        margin-left: $margin-left;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
        a, button {
            background-color: $button-bc;
            color: $button-fc;
            padding: 15px;
            font-size: 17px;
        }
    }
}
