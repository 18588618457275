.crop-container {
    display: flex;
    flex-direction: column;
    height: inherit;
    h2 {
        margin-left: $margin-left;
        color: $heading-color;
    }
    p {
        margin-left: $margin-left;
    }
    button {
        background-color: $button-bc;
        color: $button-fc;
        padding: 15px;
        font-size: 17px;
    }
    .bottom-buttons {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
    }
}

.cropper-point {
    height: 20px;
    width: 20px;
}
