

$button-bc: #ff6624;
$button-fc: white;

$app-button-bc: white;
$app-button-fc: #ff6624;


$heading-color: #ff6624;

$container-padding-top: 40px;

$margin-left: 15px;


body {
    margin: 0;
    padding: 0;
    font-family: 'roboto';
    height: 100vh;
    width: 100vw;
}

#root {
    height: inherit;
    width: inherit;
}

h1 {
    color: white;
    margin-top: 0;
    margin-left: 15px;
}

@import './components/photoviewer.scss';
@import './components/itemSearchForm.scss';
@import './components/imageSearchViewContainer.scss';
@import './components/cameraContainer.scss';
@import './components/cropContainer.scss';
@import './components/itemSearchContainer.scss';
@import './components/addImageContainer.scss';
@import './components/app.scss';
