.item-search-container {
    h2 {
        margin-left: $margin-left;
        color: $heading-color;
    }
    button {
        background-color: $button-bc;
        color: $button-fc;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 15px;
        font-size: 17px;
    }
    th {
        text-align: left;
    }
    td {
        text-align: right;
    }
    .search-result {
        min-height: 20%;
    }
    .button-container {
        display: flex;
        flex-direction: column;
    }
    .set-item-button {
      position: fixed;
      bottom: 0;
      width: 100%;
      margin: 0;
    }
    .hidden {
        visibility: hidden
    }
    .unhidden {
        visibility: visible;
        margin-left: $margin-left;
    }
    .form {
        .text-field{
            display: flex;
            flex-direction: column;
            margin-left: $margin-left;
            margin-right: $margin-left;
        }
    }
}
