.image-search-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    h2, h3 {
        margin-left: $margin-left;
    }
    h2 {
        color: $heading-color;
    }
    p {
        margin-left: $margin-left;
    }
    button, a {
        background-color: $button-bc;
        color: $button-fc;
    }
    .bottom-buttons {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
    }
}

.photoviewer {
    /* flex-basis: 50%;
    flex-grow: 1; */
}