.app-container {
    background-image: url('../assets/background.png');
    padding-top: $container-padding-top;
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        a, button {
            background-color:$app-button-bc;
            color: $app-button-fc;
            margin-bottom: 30px;
            padding: 15px;
            font-size: 17px;
        }
    }
}
