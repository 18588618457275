.camera-container {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: inherit;
    h2 {
        margin-left: $margin-left;
        color: $heading-color;
    }
    button, a {
        background-color: $button-bc;
        color: $button-fc;
        margin-top: 10px;
        padding: 15px;
        font-size: 17px;
    }
    .bottom-buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
    }
    .image-box {
        margin-left: $margin-left;
    }

    img {
      margin-left: 50%;
      transform: translateX(-50%);
    }
}

.fileContainer {
    overflow: hidden;
    position: relative;
}

.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}
